import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import Knob from './Knob';
import { Stack } from '@mui/system';


class OutputLevel extends React.Component {
  render() {
    return (
      <Stack alignItems='center' spacing={0}>
        <Stack direction="row" spacing={2}>
          <Knob label="Level" minValue={0} maxValue={99} value={this.props.outputLevel} />
        </Stack>
        <Typography variant='overline'>Output</Typography>
      </Stack>
    );
  }
}

OutputLevel.propTypes = {
  outputLevel: PropTypes.number.isRequired
};

export default OutputLevel;
