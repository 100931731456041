import _ from 'lodash';

import { db } from '../firebase';
import { doc, getDoc, runTransaction } from 'firebase/firestore';


export async function getFavorites(user) {
  const docRef = doc(db, "favorites", user.email);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return {
      patch_ids: []
    };
  }
}

export async function addFavorite(user, patch_id) {
  const docRef = doc(db, "favorites", user.email);

  await runTransaction(db, async (transaction) => {
    const doc = await transaction.get(docRef);

    if (!doc.exists()) {
      transaction.set(docRef, {
        patch_ids: [patch_id]
      });
    } else {
      const patchIds = doc.data().patch_ids;

      if (!(_.includes(patchIds, patch_id))) {
        patchIds.push(patch_id);
        transaction.update(docRef, {
          patch_ids: patchIds
        });
      }
    }
  });
}

export async function removeFavorite(user, patch_id) {
  const docRef = doc(db, "favorites", user.email);

  await runTransaction(db, async (transaction) => {
    const doc = await transaction.get(docRef);

    if (!doc.exists()) {
      transaction.set(docRef, {
        patch_ids: []
      });
    } else {
      const patchIds = doc.data().patch_ids.filter((p) => {return p !== patch_id});

      transaction.update(docRef, {
        patch_ids: patchIds
      });
    }
  });
}