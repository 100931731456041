import React from 'react';
import PropTypes from 'prop-types';

import {Piano, MidiNumbers} from 'react-piano';
import 'react-piano/dist/styles.css';
import './pianoStyleOverrides.css';

import DX7Synth from '../vendor/dx7-synth-js/synth';
import FMVoice from '../vendor/dx7-synth-js/voice-dx7';
import DX7MIDI from '../vendor/dx7-synth-js/midi';
import SysexDX7 from '../vendor/dx7-synth-js/sysex-dx7';
import config from '../vendor/dx7-synth-js/config';

import { loadDX7Sysex } from './patch-utils';

import { Button, Typography } from '@mui/material';
import { VolumeUp } from '@mui/icons-material';
import { Stack } from '@mui/system';


var BUFFER_SIZE_MS = 1000 * config.bufferSize / config.sampleRate;
var MS_PER_SAMPLE = 1000 / config.sampleRate;


class Synth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dx7: null,
      activeNotes: [],
      loadFailed: false,
      audioContext: null,
      webAudioEnabled: false,
    };
  }

  loadSynthPatch() {
    return this.fetchPatchData().then(function(patchData) {
      var voices = SysexDX7.loadBank(patchData);

      var params = voices[0];

      FMVoice.setParams(params);

      for (var i = 0; i < params.operators.length; i++) {
			  var op = params.operators[i];
			  FMVoice.setOutputLevel(i, op.volume);
			  FMVoice.updateFrequency(i);
			  FMVoice.setPan(i, op.pan);
		  }
		  FMVoice.setFeedback(params.feedback);
    });
  }

  async fetchPatchData() {
    return (await loadDX7Sysex(this.props.voiceId)).text();
  }

  loadDX7() {
    if (!this.state.dx7) {
      return Promise.resolve().then(function() {
        var synth = new DX7Synth(FMVoice, config.polyphony);
        var midi = new DX7MIDI(synth);

        var audioContext = new (window.AudioContext || window.webkitAudioContext)();
        var scriptProcessor = 	scriptProcessor = audioContext.createScriptProcessor(config.bufferSize, 0, 2); /* eslint-disable-line */
	      scriptProcessor.connect(audioContext.destination);

	      // Attach to window to avoid GC. http://sriku.org/blog/2013/01/30/taming-the-scriptprocessornode
	      scriptProcessor.onaudioprocess = window.audioProcess = function (e) {
		      var buffer = e.outputBuffer;
		      var outputL = buffer.getChannelData(0);
		      var outputR = buffer.getChannelData(1);

		      var sampleTime = performance.now() - BUFFER_SIZE_MS;

		      for (var i = 0, length = buffer.length; i < length; i++) {
			      sampleTime += MS_PER_SAMPLE;
			      if (synth.eventQueue.length && synth.eventQueue[0].timeStamp < sampleTime) {
				      synth.processMidiEvent(synth.eventQueue.shift());
			      }

			      var output = synth.render();
			      outputL[i] = output[0];
			      outputR[i] = output[1];
		      }
	      };

        this.setState({
          dx7: {synth: synth, midi: midi},
          audioContext: audioContext,
          webAudioEnabled: audioContext.state === 'running'
        });
        return Promise.resolve(synth);
      }.bind(this));
    } else {
      return Promise.resolve(this.state.dx7);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.voiceId !== prevProps.voiceId) {
      this.loadSynthPatch().catch(function(err) {
        this.setState({loadFailed: true});
      }.bind(this));
    }
  }

  handleMidiMessage(midiMessage) {
    if (midiMessage.data[0] === 144) {
      this.setState((prevState) => ({activeNotes: [...prevState.activeNotes, midiMessage.data[1]]}));
    } else if(midiMessage.data[0] === 128)  {
      this.setState((prevState) => ({activeNotes: prevState.activeNotes.filter((n) => {return n !== midiMessage.data[1];})}));
    }
  }

  componentDidMount() {
    Promise.resolve().then(function() {
      if ('requestMIDIAccess' in navigator) {
        return navigator.requestMIDIAccess().then(function(midiAccess) {
          for (var input of midiAccess.inputs.values()) {
            input.onmidimessage = this.handleMidiMessage.bind(this);
          }
        }.bind(this));
      } else {
        return Promise.resolve();
      }
    }.bind(this)).then(function() {
      return this.loadDX7();
    }.bind(this)).then(function() {
      this.loadSynthPatch();
    }.bind(this)).catch(function(err) {
      console.log(err);
      this.setState({loadFailed: true});
    }.bind(this));
  }

  render() {
    if (this.state.loadFailed) {
      return <Typography>Live preview only supported in browsers that support Web Audio.</Typography>;
    }

    if (!this.state.dx7) {
      return <Typography>Loading ...</Typography>;
    }

    let enableWebAudioButton = null;


    if (!(this.state.webAudioEnabled)) {
      enableWebAudioButton = (
        <Button variant="contained" startIcon={<VolumeUp/>} color={this.state.webAudioEnabled ? 'primary' : 'secondary'} disabled={this.state.webAudioEnabled} onClick={() => { this.state.audioContext.resume().then(() => { this.setState({ webAudioEnabled: true }) }) }}>Click to Enable Piano</Button>
      );
    }

    return (
      <Stack spacing={1} direction="column" alignItems='center' justifyContent='center'>
        <div className='synth'>
          <Piano noteRange={{ first: MidiNumbers.fromNote('c2'), last: MidiNumbers.fromNote('c5') }}
            playNote={(midiNumber) => { this.state.dx7.synth.noteOn(midiNumber, 2); }}
            stopNote={(midiNumber) => { this.state.dx7.synth.noteOff(midiNumber, 2); }}
            activeNotes={this.state.activeNotes}
            width={450} disabled={!(this.state.webAudioEnabled)}/>
        </div>
        {enableWebAudioButton}
      </Stack>
    );
  }
}

Synth.propTypes = {
  voiceId: PropTypes.string.isRequired
};

export default Synth;
