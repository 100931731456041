import React, { useEffect, useState } from 'react';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Button } from '@mui/material';
import _ from 'lodash';

import { addFavorite, getFavorites, removeFavorite } from './favorites';

import { auth, db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';

export default function FavoritesButton(props) {
  const { voiceId } = props;

  const [favorited, setFavorited] = useState(false);
  const [ trackingFavoritesList, setTrackingFavoritesList ] = useState(false);
  const [user, setUser] = useState(null);

  const toggleFavorite = async () => {
    try {
      if (favorited) {
        await removeFavorite(user, voiceId);
      } else {
        await addFavorite(user, voiceId);
      }
    } catch (err) {
      // FIXME do something more intelligent here
      console.log(err);
    }
  };

  const updateFavoritedState = async () => {
    if (user) {
      const favoritesList = await getFavorites(user);
      const patchIsFavorited = _.includes(favoritesList.patch_ids, props.voiceId);

      setFavorited(patchIsFavorited);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return function cleanup() {
      unsubscribe();
    }
  });

  useEffect(() => {
    if (user && !trackingFavoritesList) {
      const unsubscribe = onSnapshot(doc(db, "favorites", user.email), (doc) => {
        if (!doc) {
          return;
        }

        let favoritesList = doc.data();

        if (favoritesList) {
          setFavorited(_.includes(favoritesList.patch_ids, voiceId));
        }
      });

      setTrackingFavoritesList(true);

      return unsubscribe;
    }
  }, [user]);

  useEffect(() => {
    updateFavoritedState(user).catch((err) => {
      // FIXME do something more intelligent here
      console.log(err);
    });
  });

  if (!user) {
    return (
      <Button color="secondary" size="small" variant="contained"
              startIcon={<FavoriteBorderIcon />}
              disabled aria-label="Login to Favorite">
        Login to Favorite
      </Button>
    );
  }

  return (
    <Button color="secondary" size="small" variant="contained"
      startIcon={<FavoriteBorderIcon />}
      onClick={toggleFavorite} aria-label={favorited ? "Unfavorite" : "Favorite"}>
      { favorited ? "Unfavorite" : "Favorite" }
    </Button>
  );
}