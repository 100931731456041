import React, { useState } from 'react';

import { Line } from 'react-chartjs-2';
import { LineElement, CategoryScale, LinearScale, PointElement, Filler, Chart as ChartJS } from 'chart.js';

import { Typography } from '@mui/material';

import Knob from './Knob';
import { Stack } from '@mui/system';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Filler);

export default function EnvelopeGenerator(props) {
  const { levels, rates } = props;

  const [chartData, _] = useState({ // eslint-disable-line
    datasets: [
      {
        data: [{ x: 0, y: levels[3] },
        { x: rates[0], y: levels[0] },
        { x: rates[0] + rates[1], y: levels[1] },
        { x: rates[0] + rates[1] + rates[2], y: levels[2] },
        { x: rates[0] + rates[1] + rates[2] + rates[3], y: levels[3] }],
        tension: 0,
        fill: { value: 0 },
      }
    ]
  });

  const chartOptions = {
    animation: {
      duration: 0
    },
    tooltips: {
      enabled: false
    },
    elements: {
      point: {
        radius: 2,
        hoverRadius: 2,
        hitRadius: 2
      }
    },
    scales: {
      x: {
        type: 'linear',
        min: 0,
        max: rates[0] + rates[1] + rates[2] + rates[3] + 10,
        display: false,
        border: {
          display: false
        },
        ticks: {
          display: false
        },
        grid: {
          display: false
        },
        offset: true
      },
      y: {
        type: 'linear',
        min: 0,
        max: 110,
        display: false,
        border: {
          display: false
        },
        ticks: {
          display: false
        },
        grid: {
          display: false
        },
        offset: true
      }
    },
    legend: {
      display: false
    },
    responsive: false,
    maintainAspectRatio: true
  };

  return (
    <Stack spacing={1} alignItems="flex-start" direction="column">

      <Line data={chartData} options={chartOptions} height='100px' width='200px' />

      <Stack spacing={0}>
        <Stack spacing={0} alignItems="center">
          <Stack direction="row">
            <Knob label={'1'} value={levels[0]} minValue={0} maxValue={99} />
            <Knob label={'2'} value={levels[1]} minValue={0} maxValue={99} />
            <Knob label={'3'} value={levels[2]} minValue={0} maxValue={99} />
            <Knob label={'4'} value={levels[3]} minValue={0} maxValue={99} />
          </Stack>
          <Typography variant="overline">Levels</Typography>
        </Stack>

        <Stack spacing={0} alignItems="center">
          <Stack direction="row">
            <Knob label={'1'} value={rates[0]} minValue={0} maxValue={99} />
            <Knob label={'2'} value={rates[1]} minValue={0} maxValue={99} />
            <Knob label={'3'} value={rates[2]} minValue={0} maxValue={99} />
            <Knob label={'4'} value={rates[3]} minValue={0} maxValue={99} />
          </Stack>
          <Typography variant="overline">Rates</Typography>
        </Stack>

      </Stack>
    </Stack>
  );
}
