import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../firebase';
import _ from 'lodash';
import { Favorite } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function renderPatchName(params) {
  return (<Typography sx={{color: 'primary.main', textDecoration: 'underline'}}>{params.value}</Typography>);
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

const getApplyCaseInsensitiveContainsFilter = (value) => {
  if (!value || value.length === 0) {
    // Don't filter when the search bar is empty, or for empty strings
    return null;
  }

  return (params) => {
    return params.value.toLowerCase().includes(value.toLowerCase());
  };
};

export default function PatchList(props) {
  const [patchList, setPatchList] = useState(props.patchList);
  const [user, setUser] = useState(null);
  const [ trackingFavoritesList, setTrackingFavoritesList ] = useState(false);
  const [ favoritesList, setFavoritesList ] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return function cleanup() {
      unsubscribe();
    }
  });

  useEffect(() => {
    if (user && !trackingFavoritesList) {
      const unsubscribe = onSnapshot(doc(db, "favorites", user.email), (doc) => {
        if (!doc) {
          return;
        }

        console.log('Updating patch list favorites ...');

        let favoritesListDoc = doc.data();

        if (favoritesListDoc) {
          setFavoritesList(favoritesListDoc.patch_ids);
          setPatchList(patchList.map((row) => {
            return {...row, favorite: _.includes(favoritesList, row.signature)}
          }));
        }
      });

      setTrackingFavoritesList(true);

      return unsubscribe;
    } else if (!user) {
      setFavoritesList([]);
      setPatchList(props.patchList);
    }
  }, [user]);

  const getFavorited = (params) => {
    const { signature } = params.row;

    if (_.includes(favoritesList, signature)) {
      return 1;
    } else {
      return 0;
    }
  };

  const renderFavorited = (params) => {
    const favorite = params.value;

    if (favorite) {
      return (<Favorite color="secondary" />);
    } else {
      return (<span></span>);
    }
  };

  const columns = [
    { headerName: 'Favorite', field: 'favorite', type: 'number', width: 90,
      valueGetter: getFavorited,
      renderCell: renderFavorited,
      sortComparator: (a, b) => { return a - b; }
    },
    { headerName: 'Name', field: 'name', width: 200,
      getApplyQuickFilterFn: getApplyCaseInsensitiveContainsFilter,
      renderCell: renderPatchName
    },
    { headerName: 'Author', field: 'author', width: 120,
      getApplyQuickFilterFn: getApplyCaseInsensitiveContainsFilter },
    { headerName: 'Source Bank', field: 'source_bank', width: 100,
      getApplyQuickFilterFn: getApplyCaseInsensitiveContainsFilter }
  ];

  const handleOnCellClick = (params) => {
    const { model, signature } = params.row;

    navigate(`/patches/${model}/${signature}`);
  };

  return (
    <div style={{ height: 450, minWidth: 500, maxWidth: '100%' }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{field: 'favorite', sort: 'desc'}]
          }
        }}
        rows={patchList}
        columns={columns}
        getRowId={(row) => row.signature}
        components={{ Toolbar: QuickSearchToolbar }}
        onCellClick={handleOnCellClick}
        disableColumnFilter
      />
    </div>
  );
}