var algorithms = [
  // Algorithm 1
  {
    carriers: [1, 3],
    grid: [
      [1, 2],
      [3, 4, 5, 6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4, 5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 2
  {
    carriers: [1, 3],
    grid: [
      [1, 2],
      [3, 4, 5, 6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4, 5, 6]
    ],
    feedbackLoops: [
      [2, 2]
    ]
  },
  // Algorithm 3
  {
    carriers: [1, 4],
    grid: [
      [1, 2, 3],
      [4, 5, 6]
    ],
    connectionPaths: [
      [1, 2, 3],
      [4, 5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 4
  {
    carriers: [1, 4],
    grid: [
      [1, 2, 3],
      [4, 5, 6]
    ],
    connectionPaths: [
      [1, 2, 3],
      [4, 5, 6]
    ],
    feedbackLoops: [
      [6, 4]
    ]
  },
  // Algorithm 5
  {
    carriers: [1, 3, 5],
    grid: [
      [1, 2],
      [3, 4],
      [5, 6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4],
      [5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 6
  {
    carriers: [1, 3, 5],
    grid: [
      [1, 2],
      [3, 4],
      [5, 6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4],
      [5, 6],
    ],
    feedbackLoops: [
      [6, 5]
    ]
  },
  // Algorithm 7
  {
    carriers: [1, 3],
    grid: [
      [1, 2],
      [3, 4],
      [null, 5, 6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4],
      [3, 5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 8
  {
    carriers: [1, 3],
    grid: [
      [1, 2],
      [3, 4],
      [null, 5, 6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4],
      [3, 5, 6]
    ],
    feedbackLoops: [
      [4, 4]
    ]
  },
  // Algorithm 9
  {
    carriers: [1, 3],
    grid: [
      [1, 2],
      [3, 4],
      [null, 5, 6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4],
      [3, 5, 6]
    ],
    feedbackLoops: [
      [2, 2]
    ]
  },
  // Algorithm 10
  {
    carriers: [1, 4],
    grid: [
      [1, 2, 3],
      [4, 6],
      [null, 5]
    ],
    connectionPaths: [
      [1, 2, 3],
      [4, 5],
      [4, 6]
    ],
    feedbackLoops: [
      [3, 3]
    ]
  },
  // Algorithm 11
  {
    carriers: [1, 4],
    grid: [
      [1, 2, 3],
      [4, 6],
      [null, 5]
    ],
    connectionPaths: [
      [1, 2, 3],
      [4, 5],
      [4, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 12
  {
    carriers: [1, 3],
    grid: [
      [1, 2],
      [null, 4],
      [3, 5],
      [null, 6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4],
      [3, 5],
      [3, 6]
    ],
    feedbackLoops: [
      [2, 2]
    ]
  },
  // Algorithm 13
  {
    carriers: [1, 3],
    grid: [
      [1, 2],
      [null, 4],
      [3, 5],
      [null, 6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4],
      [3, 5],
      [3, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 14
  {
    carriers: [1, 3],
    grid: [
      [1, 2],
      [3, 4, 6],
      [null, null, 5]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4, 5],
      [3, 4, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 15
  {
    carriers: [1, 3],
    grid: [
      [1, 2],
      [3, 4, 5],
      [null, null, 6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4, 5],
      [4, 6]
    ],
    feedbackLoops: [
      [2, 2]
    ]
  },
  // Algorithm 16
  {
    carriers: [1],
    grid: [
      [null, 2],
      [1, 3, 4],
      [null, 5, 6]
    ],
    connectionPaths: [
      [1, 2],
      [1, 3, 4],
      [1, 5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 17
  {
    carriers: [1],
    grid: [
      [null, 2],
      [1, 3, 4],
      [null, 5, 6]
    ],
    connectionPaths: [
      [1, 2],
      [1, 3, 4],
      [1, 5, 6]
    ],
    feedbackLoops: [
      [2, 2]
    ]
  },
  // Algorithm 18
  {
    carriers: [1],
    grid: [
      [null, 2],
      [1, 3],
      [null, 4, 5, 6]
    ],
    connectionPaths: [
      [1, 2],
      [1, 3],
      [1, 4, 5, 6]
    ],
    feedbackLoops: [
      [3, 3]
    ]
  },
  // Algorithm 19
  {
    carriers: [1, 4, 5],
    grid: [
      [1, 2, 3],
      [4, 6],
      [5]
    ],
    connectionPaths: [
      [1, 2, 3],
      [4, 6],
      [5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 20
  {
    carriers: [1, 2, 4],
    grid: [
      [1, 3],
      [2],
      [null, 5],
      [4, 6]
    ],
    connectionPaths: [
      [1, 3],
      [2, 3],
      [4, 5],
      [4, 6]
    ],
    feedbackLoops: [
      [3, 3]
    ]
  },
  // Algorithm 21
  {
    carriers: [1, 2, 4, 5],
    grid: [
      [1, 3],
      [2],
      [4, 6],
      [5]
    ],
    connectionPaths: [
      [1, 3],
      [2, 3],
      [4, 6],
      [5, 6]
    ],
    feedbackLoops: [
      [3, 3]
    ]
  },
  // Algorithm 22
  {
    carriers: [1, 3, 4, 5],
    grid: [
      [1, 2],
      [3],
      [4, 6],
      [5]
    ],
    connectionPaths: [
      [1, 2],
      [3, 6],
      [4, 6],
      [5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 23
  {
    carriers: [1, 2, 4, 5],
    grid: [
      [1],
      [2, 3],
      [4, 6],
      [5]
    ],
    connectionPaths: [
      [2, 3],
      [4, 6],
      [5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 24
  {
    carriers: [1, 2, 3, 4, 5],
    grid: [
      [1],
      [2],
      [3],
      [4, 6],
      [5]
    ],
    connectionPaths: [
      [3, 6],
      [4, 6],
      [5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 25
  {
    carriers: [1, 2, 3, 4, 5],
    grid: [
      [1],
      [2],
      [3],
      [4, 6],
      [5]
    ],
    connectionPaths: [
      [4, 6],
      [5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 26
  {
    carriers: [1, 2, 4],
    grid: [
      [1],
      [2, 3],
      [null, 5],
      [4, 6]
    ],
    connectionPaths: [
      [2, 3],
      [4, 5],
      [4, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 27
  {
    carriers: [1, 2, 4],
    grid: [
      [1],
      [2, 3],
      [null, 5],
      [4, 6]
    ],
    connectionPaths: [
      [2, 3],
      [4, 5],
      [4, 6]
    ],
    feedbackLoops: [
      [3, 3]
    ]
  },
  // Algorithm 28
  {
    carriers: [1, 3, 6],
    grid: [
      [1, 2],
      [3, 4, 5],
      [6]
    ],
    connectionPaths: [
      [1, 2],
      [3, 4, 5]
    ],
    feedbackLoops: [
      [5, 5]
    ]
  },
  // Algorithm 29
  {
    carriers: [1, 2, 3, 5],
    grid: [
      [1],
      [2],
      [3, 4],
      [5, 6]
    ],
    connectionPaths: [
      [3, 4],
      [5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 30
  {
    carriers: [1, 2, 3, 6],
    grid: [
      [1],
      [2],
      [3, 4, 5],
      [6]
    ],
    connectionPaths: [
      [3, 4, 5]
    ],
    feedbackLoops: [
      [5, 5]
    ]
  },
  // Algorithm 31
  {
    carriers: [1, 2, 3, 4, 5],
    grid: [
      [1],
      [2],
      [3],
      [4],
      [5, 6]
    ],
    connectionPaths: [
      [5, 6]
    ],
    feedbackLoops: [
      [6, 6]
    ]
  },
  // Algorithm 32
  {
    carriers: [1, 2, 3, 4, 5, 6],
    grid: [
      [1],
      [2],
      [3],
      [4],
      [5],
      [6]
    ],
    connectionPaths: [
    ],
    feedbackLoops: [
      [6, 6]
    ]
  }
];

export default algorithms;