import React from 'react';
import { Container } from '@mui/material';
import { Outlet } from "react-router-dom";

import Navigator from '../components/Navigator';

import * as Sentry from '@sentry/react';
import Error from '../components/Error';

class Root extends React.Component {
  render() {
    return (
      <Container fixed sx={{ minWidth: 550 }}>
        <Navigator />
        <Sentry.ErrorBoundary fallback={<Error />}>
          <Outlet />
        </Sentry.ErrorBoundary>
      </Container>
    );
  }
}

export default Root;