import styled from '@emotion/styled';
import { DownloadForOffline, Favorite, LibraryMusic, Output, Piano } from '@mui/icons-material';
import { Grid, Link, Paper, Stack, Typography } from '@mui/material';
import React from 'react';

import Algorithm from '../components/Algorithm';

class Root extends React.Component {
  render() {
    const renderFeatureAd = (icon, color, headline, blurb) => {
      let StyledIcon = styled(icon)(({ theme }) => {
        let iconColor;

        if (color === 'primary') {
          iconColor = theme.palette.primary.main;
        } else if (color === 'secondary') {
          iconColor = theme.palette.secondary.main;
        } else {
          iconColor = color;
        }

        return {
          fontSize: 50,
          color: iconColor
        }
      });

      return (
        <Paper elevation={0} sx={{ padding: '1rem' }} square>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <StyledIcon />
            <Typography variant="body1"><b>{headline}.</b> {blurb}.</Typography>
          </Stack>
        </Paper>
      );
    };

    return (
      <Stack spacing={4}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={12} lg={5}>
            <Algorithm algorithm={14} feedback={2} scale={2} showLabel={false} />
          </Grid>
          <Grid item md={12} lg={7}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h2" align='center' sx={{ marginTop: '3rem', marginBottom: '3rem' }}>The Performance is About to Begin</Typography>
              <Typography variant="body1" align="center">
                Welcome to your one-stop shop for patches for the <Link href="">Yamaha DX7</Link> and compatible synthesizers.
              </Typography>
              <Typography variant="body1" align="center"><b>Ready to get started?</b> <Link href="/patches/dx7/">Go to the patches</Link> or click "Patches" above.</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="center" spacing={2} columns={9}>
          <Grid item xs={4}>
            {renderFeatureAd(LibraryMusic, "black", "The most complete collection of DX7 patches on the web", "All the DX7 patches on the web, without all the duplicates")}
          </Grid>
          <Grid item xs={4}>
            {renderFeatureAd(Piano, "black", "Preview patches in your browser", "Through the power of Web Audio, listen to a patch before you download it")}
          </Grid>

          <Grid item xs={4}>
            {renderFeatureAd(DownloadForOffline, "black", "Download patches", "Download individual SysEx files for use in your patch librarian, synth or VST of choice")}
          </Grid>

          <Grid item xs={4}>
            {renderFeatureAd(Output, "black", "Transfer patches directly to your synth", "Connect your synth to your computer and transfer patches directly from the web to your synth through the magic of Web MIDI")}
          </Grid>

          <Grid item xs={4}>
            {renderFeatureAd(Favorite, "secondary", "Keep track of your favorites", "Save your favorite patches and manage them all in one place")}
          </Grid>
        </Grid>
      </Stack>
    );
  }
};

export default Root;