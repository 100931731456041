import React from 'react';
import { useLoaderData, Outlet } from "react-router-dom";

import PatchList from '../components/PatchList';
import { loadDX7PatchList } from '../components/patch-utils';
import { Grid } from '@mui/material';


export default function DX7PatchList() {
  const patchList = useLoaderData();

  return (
    <Grid container justifyContent="center" alignItems="flex-start" spacing={4} columns={{xs: 1, lg: 4}}>
      <Grid item xs={1} lg={2}>
        <PatchList patchList={patchList} />
      </Grid>
      <Grid item xs={1} lg={2}>
        <Outlet />
      </Grid>
    </Grid>
  );
}

export async function loader() {
  return loadDX7PatchList();
}
