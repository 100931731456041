import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Button, Link, Stack, TextField, Typography } from "@mui/material";

import { auth } from '../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";


export default function Signup() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");

  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate("/account-created");
    } catch (err) {
      let errorMessage;

      switch (err.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'E-mail address has already been registered'
          break;
        case 'auth/invalid-email':
          errorMessage = 'E-mail address is invalid'
          break;
        case 'auth/weak-password':
          errorMessage = 'Password is too easy to guess; pick a stronger password'
          break;
        default:
          errorMessage = `An unknown error (${err.code}) occurred`
          break;
      }

      setError(`Signup failed: ${errorMessage}`);
    }
  };

  let alert;

  if (error) {
    alert = <Alert severity="error">{error}</Alert>;
  } else {
    alert = null;
  }

  return (
<Stack justifyContent="center" alignItems="center" spacing={2}>
    <Typography variant="h5">Create a new patches.fm account</Typography>
    <TextField required id="email" label="E-Mail Address" onChange={(event) => { setEmail(event.target.value) }} />
    <TextField required id="password" type="password" label="Password" onChange={(event => { setPassword(event.target.value) })} />
    <Typography>Already have an account? <Link href="/login">Sign In</Link></Typography>
    <Button onClick={handleSubmit} variant="contained">Create Account</Button>
    {alert}
  </Stack>

    );
};