import React from 'react';
import PropTypes from 'prop-types';

import {Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';

import EnvelopeGenerator from './EnvelopeGenerator';
import Keyboard from './Keyboard';
import Oscillator from './Oscillator';
import Sensitivity from './Sensitivity';
import OutputLevel from './OutputLevel';
import { Stack } from '@mui/system';


class Operator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleClickOpen() {
    this.setState({open: true});
  }

  handleClose() {
    this.setState({open: false});
  }

  render() {  
    return (
      <React.Fragment>
        <Button size="small" variant="contained" color="primary" onClick={this.handleClickOpen.bind(this)}>
          <Typography variant="h6" sx={{color: '#ffffff'}}>{this.props.operatorNumber}</Typography>
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
          aria-labelledby='op-dialog-title'>
          <DialogTitle id='op-dialog-title'>Operator {this.props.operatorNumber}</DialogTitle>
          <DialogContent>
            <Stack spacing={1} alignItems='center' justifyContent='center'>
              <EnvelopeGenerator rates={this.props.operatorParams.envelope_generator.rates} levels={this.props.operatorParams.envelope_generator.levels} />
              <Keyboard levelScaling={this.props.operatorParams.keyboard.level_scaling} rateScaling={this.props.operatorParams.keyboard.rate_scaling}/>
              <Stack direction="row" justifyContent='space-evenly' spacing={2}>
                <Oscillator oscillatorParams={this.props.operatorParams.oscillator} />
                <Sensitivity ampModSensitivity={this.props.operatorParams.amp_mod_sensitivity} velocitySensitivity={this.props.operatorParams.keyboard.velocity_sensitivity} />
                <OutputLevel outputLevel={this.props.operatorParams.output_level}/>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ borderTopWidth: '1px', borderTopStyle: 'solid', borderTopColor: 'divider' }}>
            <Button onClick={this.handleClose.bind(this)} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

Operator.propTypes = {
  operatorParams: PropTypes.object.isRequired,
  operatorNumber: PropTypes.number.isRequired
};

export default Operator;
