import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDhnnMP20WrTJ18NfyHF6dquSZRUaQeH1g",
  authDomain: "patches-fm.firebaseapp.com",
  projectId: "patches-fm",
  storageBucket: "patches-fm.appspot.com",
  messagingSenderId: "406907293775",
  appId: "1:406907293775:web:0bf887b3bacb4982ea1a3a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {app, auth, db};