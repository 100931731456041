import { ThemeProvider, createTheme } from '@mui/material/styles';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './App.css';

import Root from './routes/Root';
import Home from './routes/Home';
import DX7Patch, {loader as dx7PatchLoader } from './routes/DX7Patch';
import DX7PatchList, {loader as dx7PatchListLoader } from './routes/DX7PatchList';
import EmptyPatchPlaceholder from './routes/EmptyPatchPlaceholder';
import UITest from './routes/UITest';
import Signup from './routes/Signup';
import Login from './routes/Login';
import AccountCreated from './routes/AccountCreated';
import ForgotPassword from './routes/ForgotPassword';
import NotFound from './routes/NotFound';

const theme = createTheme({
  palette: {
    primary: {
      main: '#19c0a9'
    },
    secondary: {
      main: '#f50057'
    }
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: '/signup',
        element: <Signup />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/account-created',
        element: <AccountCreated />
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: "patches/dx7",
        element: <DX7PatchList />,
        loader: dx7PatchListLoader,
        children: [
          {
            path: "/patches/dx7",
            element: <EmptyPatchPlaceholder />
          },
          {
            path: ":patchID",
            element: <DX7Patch />,
            loader: dx7PatchLoader
          }
        ]
      },
      {
        path: "/ui_test",
        element: <UITest/>
      },
      {
        path: "*",
        element: <NotFound />
      }
    ]
  }
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
