import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@mui/material';

import Knob from './Knob';
import Waveform from './Waveform';


const KEYS = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];

class Keyboard extends React.Component {
  render() {
    // Breakpoint values start at A-1 and the scale begins at C, so we need to offset the breakpoint
    // value by 3 for the math to work out
    let breakPoint = this.props.levelScaling.break_point - 3;
    let breakPointOctave = Math.floor(breakPoint / 12);

    // Modular arithmetic in JS works differently than it does in other languages I'm used to; -3 %
    // 12 is -3, not 9 like it would be in Python. To account for this weird behavior with negative
    // numbers, just add 12 to the number and the modular arithmetic should still work out.
    const breakPointKey = KEYS[(breakPoint + 12) % 12];

    const leftCurve = this.props.levelScaling.left_curve.replace('-', 'minus-').replace('+', '').toLowerCase();
    const rightCurve = this.props.levelScaling.right_curve.replace('-', 'minus-').replace('+', '').toLowerCase();

    return (
      <Grid container direction='column' justify='flex-end' alignItems='center' spacing={2}>
        <Grid item xs={12}>
          <Grid container direction='row' justify='center' alignItems='flex-end' spacing={2}>
            <Grid item xs={2}>
              <Waveform waveform={leftCurve} caption='L Curve' />
            </Grid>
            <Grid item xs={2}>
              <Knob label={'L Depth'} minValue={0} maxValue={99} value={this.props.levelScaling.left_depth} />
            </Grid>
            <Grid item xs={2}>
              <Grid container direction='column' justify='flex-end' alignItems='center'>
                <Grid item xs={12}>
                  <Typography variant="h5">{breakPointKey + breakPointOctave}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">Breakpoint</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Knob label={'R Depth'} minValue={0} maxValue={99} value={this.props.levelScaling.right_depth} />
            </Grid>
            <Grid item xs={2}>
              <Waveform waveform={rightCurve} caption='R Curve' flip={true}/>
            </Grid>
            <Grid item xs={2}>
              <Knob label={'Rate'} value={this.props.rateScaling} minValue={0} maxValue={7}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline">Keyboard Scaling</Typography>
        </Grid>
      </Grid>
    );
  }
}

Keyboard.propTypes = {
  levelScaling: PropTypes.object.isRequired,
  rateScaling: PropTypes.number.isRequired
};

export default Keyboard;
