import SVGParamCaption from "./SVGParamCaption";


export default function Waveform(props) {
  const { waveform, caption, flip } = props;

  // waveforms.svg contains a collection of defs, one per waveform. Using this href inside 
  // an SVG <use> tag causes the appropriate def to be loaded. Past me was being very clever
  // to save loading a couple KiB of extra SVGs, and it works, so I'm keeping it. If this 
  // needs to be edited in the future, I should separate these out into their own SVGs.
  const waveformImg = `/images/waveforms.svg#${waveform.replace(/ /g, '-')}`;

  let transform = null;

  if (flip) {
    transform = "scale(-1 1) translate(-50 0)";
  }


  return (
    <svg viewBox="0 0 50 50" width="50" height="50">
      <use width='50' height='30' href={waveformImg} transform={transform} />
      <SVGParamCaption x="25" y="45" textAnchor="middle" dominantBaseline="middle">{caption || 'Wave'}</SVGParamCaption>
    </svg>
  );
}