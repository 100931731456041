import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import Knob from './Knob';
import Waveform from './Waveform';
import { Stack } from '@mui/system';


class LFO extends React.Component {
  render() {
    return (
      <Grid container sx={{ width: '300px' }} spacing={2}>
        <Grid item xs={3}>
          <Waveform waveform={this.props.lfoParams.waveform} />
        </Grid>
        <Grid item xs={3}>
          <Knob value={this.props.pitchModSensitivity} label='PMS' minValue={0} maxValue={7} />
        </Grid>
        <Grid item xs={3}>
          <Knob value={this.props.lfoParams.speed} label='Speed' minValue={0} maxValue={99} />
        </Grid>
        <Grid item xs={3}>
          <Knob value={this.props.lfoParams.delay} label='Delay' minValue={0} maxValue={99} />
        </Grid>
        <Grid item xs={3}>
          <Knob value={this.props.lfoParams.pitch_mod_depth} label='PMD' minValue={0} maxValue={99} />
        </Grid>
        <Grid item xs={3}>
          <Knob value={this.props.lfoParams.amp_mod_depth} label='AMD' minValue={0} maxValue={99} />
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0} alignItems='center'>
            <Stack spacing={0} direction='row'>
              <FormControlLabel control={<Checkbox checked={this.props.lfoParams.key_sync} />} label={<Typography variant="caption">LFO</Typography>} labelPlacement='bottom' />
              <FormControlLabel control={<Checkbox checked={this.props.oscillatorKeySync} />} label={<Typography variant="caption">Osc</Typography>} labelPlacement='bottom' />
            </Stack>
            <Typography variant={'overline'}>Key Sync</Typography>
          </Stack>
        </Grid>
      </Grid>);
  }
}

LFO.propTypes = {
  lfoParams: PropTypes.object.isRequired,
  pitchModSensitivity: PropTypes.number.isRequired,
  oscillatorKeySync: PropTypes.bool.isRequired
};

export default LFO;
