import React, { useEffect, useState } from 'react';

import { Avatar, Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { auth } from '../firebase';
import { Logout } from '@mui/icons-material';

export default function AppBarLoginState() {
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return function cleanup() {
      unsubscribe();
    }
  });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const logout = async () => {
    await auth.signOut();

    setUser(null);
  }

  const login = (event) => {
    event.preventDefault();
    setAnchorEl(null);
    navigate('/login');
  }

  if (user) {
    return (
      <div>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <IconButton
            aria-label="current user account"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            <Avatar>{user.email.charAt(0)}</Avatar>
          </IconButton>
        </Stack>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem disabled={true}>
            <ListItemIcon>
              <Avatar fontSize="small"/>
            </ListItemIcon>
            <span>Signed in as <b>{user.email}</b></span>
          </MenuItem>
          <Divider />
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small"/>
            </ListItemIcon>
            Log Out
          </MenuItem>
        </Menu>
      </div>
    );
  } else {
    return (
      <div>
        <Button onClick={login} variant="contained" color="secondary">Log In</Button>
      </div>
    );
  }
}