import React from 'react';

import { Button } from '@mui/material';

const style = {
  margin: '0 1rem',
  color: '#ffffff'
}

class ActionButton extends React.Component {
  render() {
    if (this.props.visible === false) {
      return (<div sx={style}></div>);
    } else {
      return (
        <Button
          sx={style}
          color={this.props.color || "secondary"}
          variant="contained"
          size="small"
          startIcon={this.props.icon}
          disabled={this.props.disabled}
          aria-label={this.props.label}
          onClick={this.props.onClick}>{
            this.props.label}
        </Button>
      );
    }
  }
}

export default ActionButton;
