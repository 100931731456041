import { MusicOffRounded } from "@mui/icons-material";
import { Link, Stack, Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <MusicOffRounded sx={{fontSize: '6rem'}}/>
      <Typography variant="h2">Page Not Found</Typography>
      <Typography variant="body1">If you're lost, you might want to go <Link href="/">home</Link></Typography>
    </Stack>
  );
}