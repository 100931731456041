import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import Knob from './Knob';
import { Stack } from '@mui/system';


class Sensitivity extends React.Component {
  render() {
    return (
      <Stack spacing={0} alignItems='center'>
        <Stack direction='row' spacing={2}>
          <Knob label="Mod" value={this.props.ampModSensitivity} minValue={0} maxValue={3} />
          <Knob label="Velo" value={this.props.velocitySensitivity} minValue={0} maxValue={7} />
        </Stack>
        <Typography variant="overline">Sensitivity</Typography>
      </Stack>
    );
  }
}

Sensitivity.propTypes = {
  ampModSensitivity: PropTypes.number.isRequired,
  velocitySensitivity: PropTypes.number.isRequired
};

export default Sensitivity;
