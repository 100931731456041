import React from 'react';
import PropTypes from 'prop-types';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { Grid, Typography } from '@mui/material';

import { saveAs } from 'file-saver';

import ActionButton from './ActionButton';
import Algorithm from './Algorithm';
import EnvelopeGenerator from './EnvelopeGenerator';
import FavoritesButton from './FavoritesButton';
import LFO from './LFO';
import Synth from './Synth';
import { loadDX7PatchJSON, loadDX7Sysex } from './patch-utils';
import MIDISetup from './MIDISetup';
import { Stack } from '@mui/system';
import OperatorGrid from './OperatorGrid';

class Voice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voiceParams: null
    };
  }

  getVoiceData() {
    if (this.props.voiceId && (!(this.state.voiceParams) || this.props.voiceId !== this.state.voiceParams.SIGNATURE)) {
      loadDX7PatchJSON(this.props.voiceId).then(function (data) {
        if (!(this.state.voiceParams) || this.state.voiceParams.SIGNATURE !== data.SIGNATURE) {
          this.setState({ voiceParams: data });
        }
      }.bind(this)).catch(function (err) {
        this.setState({ loadFailed: err });
      }.bind(this));
    }
  }

  componentDidUpdate() {
    this.getVoiceData();
  }

  componentDidMount() {
    this.getVoiceData();
  }

  async handleSysexDownload() {
    const sysex = await loadDX7Sysex(this.props.voiceId, true);
    const blob = await sysex.blob();

    return saveAs(blob, `${this.state.voiceParams.NAME}.syx`);
  }

  render() {
    if (this.state.loadFailed) {
      return (<Typography>Load Failed!</Typography>);
    } else if (this.state.voiceParams === null) {
      return (<Typography>No Voice Loaded</Typography>);
    } else {

      return (
        <Grid container direction='row' alignItems="flex-end" justifyContent="center" columns={{ xs: 4 }} spacing={2} sx={{ maxWidth: 800 }}>
          <Grid item xs={4}>
            <Synth voiceId={this.props.voiceId} />
          </Grid>
          <Grid item xs={4}>
            <MIDISetup voiceId={this.state.voiceParams.SIGNATURE} />
          </Grid>
          <Grid item xs={2}>
            <Stack alignItems="center">
              <FavoritesButton voiceId={this.props.voiceId} />
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack alignItems="center">
              <ActionButton label='Download SysEx' icon={<ArrowDownwardIcon />} onClick={this.handleSysexDownload.bind(this)} />
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack alignItems="center">
            <EnvelopeGenerator rates={this.state.voiceParams.pitch_envelope_generator.rates} levels={this.state.voiceParams.pitch_envelope_generator.levels} />
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack alignItems="center">
              <LFO lfoParams={this.state.voiceParams.lfo} pitchModSensitivity={this.state.voiceParams.pitch_mod_sensitivity}
                oscillatorKeySync={this.state.voiceParams.oscillator_key_sync} />
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Algorithm algorithm={this.state.voiceParams.algorithm} feedback={this.state.voiceParams.feedback} />
          </Grid>
          <Grid item xs={2}>
            <Stack alignItems="center">
              <OperatorGrid operators={this.state.voiceParams.operators} />
            </Stack>
          </Grid>
        </Grid>
      );
    }
  }
}

Voice.propTypes = {
  voiceId: PropTypes.string
};

export default Voice;
