import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import Knob from './Knob';
import { Stack } from '@mui/system';


class Oscillator extends React.Component {
  render() {
    return (
      <Stack alignItems='center' spacing={0}>
        <Stack direction="row" spacing={2}>
          <Knob label='Detune' value={this.props.oscillatorParams.detune} minValue={-7} maxValue={7} />
          <Knob label='Coarse' value={this.props.oscillatorParams.frequency.coarse} minValue={0} maxValue={31} />
          <Knob label='Fine' value={this.props.oscillatorParams.frequency.fine} minValue={0} maxValue={99} />
        </Stack>
        <Typography variant='overline'>Oscillator ({this.props.oscillatorParams.mode === 'ratio' ? 'Ratio' : 'Fixed'})</Typography>
      </Stack>
    );
  }
}

Oscillator.propTypes = {
  oscillatorParams: PropTypes.object.isRequired
};

export default Oscillator;
