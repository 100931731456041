import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import Operator from './Operator';

class OperatorGrid extends React.Component {
  render() {
    const { operators} = this.props;

    const operatorWidth = 4;

    return (<Stack alignItems='center' justifyContent='flex-end' sx={{ maxWidth: 250 }}>
      <Grid container direction='row' alignItems='flex-start' justifyContent='center' spacing={2}>
        <Grid item xs={operatorWidth}>
          <Operator operatorNumber={1} operatorParams={operators[0]} />
        </Grid>
        <Grid item xs={operatorWidth}>
          <Operator operatorNumber={2} operatorParams={operators[1]} />
        </Grid>
        <Grid item xs={operatorWidth}>
          <Operator operatorNumber={3} operatorParams={operators[2]} />
        </Grid>
        <Grid item xs={operatorWidth}>
          <Operator operatorNumber={4} operatorParams={operators[3]} />
        </Grid>
        <Grid item xs={operatorWidth}>
          <Operator operatorNumber={5} operatorParams={operators[4]} />
        </Grid>
        <Grid item xs={operatorWidth}>
          <Operator operatorNumber={6} operatorParams={operators[5]} />
        </Grid>
      </Grid>
      <Typography variant='overline'>Operators</Typography>
    </Stack>);
  }
}

export default OperatorGrid;