import { West } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';


export default function EmptyPatchPlaceholder() {
  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
        <West sx={{fontSize: '10rem'}}/>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body1">Please select a patch from the list on the left.</Typography>
      </Grid>
    </Grid>
  );
}
