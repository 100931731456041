import { Container, Grid, Stack } from "@mui/material";
import Algorithm from "../components/Algorithm";
import Error from "../components/Error";

export default function UITest() {
  const algorithms = [];

  const START_ALGO = 1;
  const END_ALGO = 32;

  for (let i = START_ALGO; i < END_ALGO + 1; i++) {
    algorithms.push(
      <Grid item xs={3} key={`alg${i}-grid`}>
        <Algorithm key={`alg${i}`} algorithm={i} feedback={5} />
      </Grid>
    );
  }

  return (
    <Container maxWidth='xl'>
      <Stack>
        <Grid container alignItems="flex-end">
          {algorithms}
        </Grid>
        <Error/>
      </Stack>
    </Container>
  );
}
