import { Button, Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/system';
import React from 'react';
import AppBarLoginState from './AppBarLoginState';

export default function Navigator() {
  return (
    <Box component="nav" sx={{ width: '100%' }}>
      <AppBar position="static" color="primary" sx={{ marginBottom: '1rem' }}>
        <Toolbar>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item sx={{flexGrow: 1}}>
              <Button variant="text" sx={{color: 'white', fontFamily: "'Orbitron', sans-serif", fontSize: '2rem', fontWeight: 900}} href="/">patches.fm</Button>
              </Grid>
              <Grid item>
              <Button variant="text primary" sx={{ flexGrow: 1, color: "white", fontWeight: 500, fontSize: '1.2rem' }} href="/patches/dx7/">Patches</Button>
            </Grid>
            <Grid item>
              <AppBarLoginState />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};