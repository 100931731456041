import { Dangerous } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

export default function Error() {
  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <Dangerous color='secondary' sx={{fontSize: '6rem'}}/>
      <Typography variant='h4'>Something Went Wrong</Typography>
      <Typography variant='body1'>Some sort of error just occurred that we didn't handle properly. Sorry about that. We'll try to fix ... whatever that was.</Typography>
      <Typography variant='body1'>In the meantime, try <b>reloading</b>.</Typography>
    </Stack>
  );
}