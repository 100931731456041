export async function loadDX7PatchJSON(patchID) {
  const response = await fetch(`/patches/dx7/${patchID.substring(0, 2)}/${patchID}.json`);
  return response.json();
}

export async function loadDX7Sysex(patchID, loadSingleVoice = false) {
  let sysexPath;

  if (loadSingleVoice) {
    sysexPath = `/patches/single-voice/dx7/${patchID.substring(0, 2)}/${patchID}.syx`;
  } else {
    sysexPath = `/patches/dx7/${patchID.substring(0, 2)}/${patchID}.syx`;
  }
  const response = await fetch(sysexPath);

  return response;
}

export async function loadDX7PatchList() {
  const response = await fetch('/patches/dx7/patch_list.json');
  return response.json();
}