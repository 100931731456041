import React from 'react';
import PropTypes from 'prop-types';

import ALGORITHMS from './algorithms';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import _ from 'lodash';


const BOX_WIDTH = 20;

class OccupiedCoordinates {
  constructor(coordinateObjects) {
    this.coords = {};

    for (let coord of coordinateObjects) {
      if (!(this.coords.hasOwnProperty(coord.x))) {
        this.coords[coord.x] = {};
      }

      this.coords[coord.x][coord.y] = true;
    }
  }

  has(i, j) {
    if (this.coords.hasOwnProperty(i)) {
      if (this.coords[i].hasOwnProperty(j)) {
        return this.coords[i][j];
      }
    }

    return false;
  }
}

class Algorithm extends React.Component {
  renderOperatorBox(i, j, operatorNumber, carrier) {
    var boxSvg = [];

    var x = BOX_WIDTH + 2 * BOX_WIDTH * i;
    var y = BOX_WIDTH + 2 * BOX_WIDTH * j;

    let operatorStyle;

    if (carrier) {
      operatorStyle = ({ theme }) => ({
        fill: theme.palette.primary.main,
        stroke: 'rgb(0, 0, 0)',
        strokeWidth: '2'
      });
    } else {
      operatorStyle = ({ theme }) => ({
        fill: 'rgb(68, 106, 190)',
        stroke: 'rgb(0, 0, 0)',
        strokeWidth: '2'
      });
    }

    const Operator = styled('rect')(operatorStyle);
    const OperatorLabel = styled('text')(({ theme }) => ({
      stroke: 'rgb(255, 255, 255)',
      fill: 'rgb(255, 255, 255)',
      strokeOpacity: '100%',
      strokeWidth: '1'
    }));

    boxSvg.push((<Operator key={`algorithm-sq${i}${j}`} x={x} y={y} width={BOX_WIDTH} height={BOX_WIDTH}></Operator>));
    boxSvg.push(<OperatorLabel key={`algorithm-label${i}${j}`} x={x + 5} y={y + 16}>{operatorNumber}</OperatorLabel>);

    return boxSvg;
  }

  getConnections(i, j) {
    var x = BOX_WIDTH + 2 * BOX_WIDTH * i;
    var y = BOX_WIDTH + 2 * BOX_WIDTH * j;

    return {
      top: {
        x: x + (BOX_WIDTH / 2),
        y: y
      },
      bottom: {
        x: x + (BOX_WIDTH / 2),
        y: y + BOX_WIDTH
      },
      left: {
        x: x,
        y: y + (BOX_WIDTH / 2)
      },
      right: {
        x: x + BOX_WIDTH,
        y: y + (BOX_WIDTH / 2)
      }
    };
  }

  renderFeedbackLoop(startI, startJ, endI, endJ) {
    const startOp = this.getConnections(startI, startJ);

    var startPoint;

    var points = [];
    var cursor;

    const LOOP_MARGIN = 0.2 * BOX_WIDTH;

    if (startI === endI && startJ === endJ) {
      // Self-connection
      startPoint = startOp.bottom;

      cursor = { x: startPoint.x, y: startPoint.y };
      points.push('M', cursor.x, cursor.y);

      // Down
      cursor.y = cursor.y + (BOX_WIDTH / 2) - LOOP_MARGIN;
      points.push('L', cursor.x, cursor.y);

      // Right
      cursor.x = cursor.x + BOX_WIDTH - LOOP_MARGIN;
      points.push('L', cursor.x, cursor.y);

      // Up
      cursor.y = cursor.y - BOX_WIDTH * 2 + (LOOP_MARGIN * 2);
      points.push('L', cursor.x, cursor.y);

      // Left
      cursor.x = cursor.x - BOX_WIDTH + LOOP_MARGIN;
      points.push('L', cursor.x, cursor.y);

      // Down
      cursor.y = cursor.y + (BOX_WIDTH / 2) - LOOP_MARGIN;
      points.push('L', cursor.x, cursor.y);
    } else {
      // Assume for simplicity that the start operator is above the end operator
      startPoint = startOp.top;

      cursor = { x: startPoint.x, y: startPoint.y };
      points.push('M', cursor.x, cursor.y);

      // Up
      cursor.y = cursor.y - (BOX_WIDTH / 2) + LOOP_MARGIN;
      points.push('L', cursor.x, cursor.y);

      // Right
      cursor.x = cursor.x + BOX_WIDTH - LOOP_MARGIN;
      points.push('L', cursor.x, cursor.y);

      // Down
      cursor.y = cursor.y + (2 * BOX_WIDTH * (Math.abs(startJ - endJ) + 1)) - (2 * LOOP_MARGIN);
      points.push('L', cursor.x, cursor.y);

      // Left
      cursor.x = cursor.x - BOX_WIDTH + LOOP_MARGIN;
      points.push('L', cursor.x, cursor.y);

      // Up
      cursor.y = cursor.y - (BOX_WIDTH / 2) + LOOP_MARGIN;
      points.push('L', cursor.x, cursor.y);
    }

    const OperatorConnection = styled('path')(({ theme }) => ({
      stroke: 'rgb(0, 0, 0)',
      fill: 'none',
      strokeOpacity: '100%',
      strokeWidth: '2'
    }));

    return (<OperatorConnection key={`feedback-connecting-${startI}-${startJ}-${endI}-${endJ}`} d={points.join(' ')} />);
  }

  renderOperatorConnection(startI, startJ, endI, endJ, occupiedCoordinates) {
    const startOp = this.getConnections(startI, startJ);
    const endOp = this.getConnections(endI, endJ);

    var startPoint;
    var endPoint;

    var points = [];
    var cursor;

    if (startI === endI) {
      if (startJ !== endJ) {
        // Connection between two operators on a column

        if (Math.abs(startJ - endJ) === 1) {
          // Direct connection between two operators

          if (startJ < endJ) {
            // Line goes down
            startPoint = startOp.bottom;
            endPoint = endOp.top;
          } else {
            // Line goes up
            startPoint = startOp.top;
            endPoint = endOp.bottom;
          }

          points = ['M', startPoint.x, startPoint.y, 'L', endPoint.x, endPoint.y];
        } else {
          startPoint = startOp.right;
          endPoint = endOp.right;

          cursor = { x: startPoint.x, y: startPoint.y };
          points.push('M', cursor.x, cursor.y);

          cursor.x = cursor.x + (BOX_WIDTH / 2);
          points.push('L', cursor.x, cursor.y);

          cursor.y = endPoint.y;
          points.push('L', cursor.x, cursor.y);

          cursor.x = endPoint.x;
          points.push('L', cursor.x, cursor.y);
        }


      }
    } else if (startJ === endJ) {
      // Connection between two operators on a row
      startPoint = startOp.bottom;
      endPoint = endOp.bottom;

      cursor = { x: startPoint.x, y: startPoint.y };
      points.push('M', cursor.x, cursor.y);

      cursor.y = cursor.y + (BOX_WIDTH / 2);
      points.push('L', cursor.x, cursor.y);

      cursor.x = endPoint.x;
      points.push('L', cursor.x, cursor.y);

      cursor.y = endPoint.y;
      points.push('L', cursor.x, cursor.y);
    } else {
      // Connection between rows and columns
      var connectionType;
      var connectionDirection;

      /**
       * There are two kinds of connections between operators that are on
       * different columns and rows. I'm calling them "z" connections (when a
       * path has a three sections, one of which spans the X distance between
       * the operators) and "l" connections (when a path has two sections
       * directly connecting the two operators without an intermediate jog
       * in the X direction)
       */

      if (startJ < endJ) {
        endPoint = endOp.top;
        connectionDirection = 'down';
      } else {
        endPoint = endOp.bottom;
        connectionDirection = 'up';
      }

      if (startI < endI) {
        if (occupiedCoordinates.has(startI + 1, startJ)) {
          if (endPoint === endOp.bottom) {
            startPoint = startOp.top;
          } else {
            startPoint = startOp.bottom;
          }

          connectionType = 'z';
        } else {
          startPoint = startOp.right;
          connectionType = 'l';
        }
      } else {
        if (occupiedCoordinates.has(startI - 1, startJ)) {
          if (endPoint === endOp.bottom) {
            startPoint = startOp.top;
          } else {
            startPoint = startOp.bottom;
          }
          connectionType = 'z';
        } else {
          startPoint = startOp.left;
          connectionType = 'l';
        }
      }

      if (connectionType === 'z') {
        cursor = { x: startPoint.x, y: startPoint.y };

        points = ['M', startPoint.x, startPoint.y];

        if (connectionDirection === 'up') {
          cursor.y = cursor.y - (BOX_WIDTH) / 2;
          points.push('L', cursor.x, cursor.y);

          cursor.x = endPoint.x;
          points.push('L', cursor.x, cursor.y);

          cursor.y = endPoint.y;
          points.push('L', cursor.x, cursor.y);
        }
      } else {
        points = ['M', startPoint.x, startPoint.y, 'L', endPoint.x, startPoint.y, 'L', endPoint.x, endPoint.y];
      }
    }

    const OperatorConnection = styled('path')(({ theme }) => ({
      stroke: 'rgb(0, 0, 0)',
      fill: 'none',
      strokeOpacity: '100%',
      strokeWidth: '2'
    }));

    return (<OperatorConnection key={`path-connecting-${startI}-${startJ}-${endI}-${endJ}-${Math.random()}`} d={points.join(' ')} />);
  }

  render() {
    const scale = this.props.scale || 1;
    const showLabel = this.props.showLabel ? this.props.showLabel : true;

    const alg = ALGORITHMS[this.props.algorithm - 1];

    var svgContents = [];
    var currentX = 0;
    var currentY = 3;

    var operatorCoordinates = {};

    for (let column of alg.grid) {
      currentY = 3;
      for (let cell of column) {
        if (cell) {
          let isCarrier = alg.carriers.indexOf(cell) >= 0;

          svgContents = svgContents.concat(this.renderOperatorBox(currentX, currentY, cell, isCarrier));
          operatorCoordinates[cell] = { x: currentX, y: currentY };
        }
        currentY--;
      }

      currentX++;
    }

    var existingConnections = new Set();

    var occupiedCoordinates = new OccupiedCoordinates(Object.values(operatorCoordinates));

    for (let connectionPath of alg.connectionPaths) {
      for (let i = 0; i < connectionPath.length - 1; i++) {
        let startCoordinates = operatorCoordinates[connectionPath[i]];
        let endCoordinates = operatorCoordinates[connectionPath[i + 1]];

        if (!existingConnections.has([connectionPath[i], connectionPath[i + 1]])) {
          svgContents = svgContents.concat(this.renderOperatorConnection(startCoordinates.x, startCoordinates.y, endCoordinates.x, endCoordinates.y,
            occupiedCoordinates));
          existingConnections.add(connectionPath[i], connectionPath[i + 1]);
        }
      }
    }

    for (let feedbackLoop of alg.feedbackLoops) {
      let startCoordinates = operatorCoordinates[feedbackLoop[0]];
      let endCoordinates = operatorCoordinates[feedbackLoop[1]];

      svgContents = svgContents.concat(this.renderFeedbackLoop(startCoordinates.x, startCoordinates.y, endCoordinates.x, endCoordinates.y));

      const feedbackLevelOpacity = 120;

      const FeedbackLabel = styled('text')(({ theme }) => ({
        stroke: `rgb(${feedbackLevelOpacity}, ${feedbackLevelOpacity}, ${feedbackLevelOpacity})`,
        fill: `rgb(${feedbackLevelOpacity}, ${feedbackLevelOpacity}, ${feedbackLevelOpacity})`,
        strokeOpacity: '100%',
        strokeWidth: '0.5',
        fontSize: '12px'
      }));

      const startOp = this.getConnections(startCoordinates.x, startCoordinates.y);

      svgContents = svgContents.concat(<FeedbackLabel key='feedback-label' x={startOp.right.x + 6} y={startOp.top.y - 8}>{this.props.feedback}</FeedbackLabel>);
    }

    for (let i = 1; i < alg.carriers.length; i++) {
      let startCoordinates = operatorCoordinates[alg.carriers[0]];
      let endCoordinates = operatorCoordinates[alg.carriers[i]];

      if (!existingConnections.has([alg.carriers[0], alg.carriers[i]])) {
        svgContents = svgContents.concat(this.renderOperatorConnection(startCoordinates.x, startCoordinates.y, endCoordinates.x, endCoordinates.y));
        existingConnections.add(alg.carriers[0], alg.carriers[i]);
      }
    }

    const operatorGridWidth = alg.grid.length;
    const operatorGridHeight = Math.max(...(alg.grid.map((x) => { return x.length })));

    // Move the entire canvas up so that the topmost row of operators is against the top edge of the canvas.
    let verticalTranslation = ((4 - operatorGridHeight) / 4.0) * (BOX_WIDTH * 9);

    // If any of the feedback operators are in the topmost row of operators, we need to add some additional padding to account for the feedback loop.
    let padTop = false;

    if (verticalTranslation > 0) {
      let topmostOperators = [];

      for (let gridCol of alg.grid) {
        if (gridCol.length === operatorGridHeight) {
          topmostOperators.push(gridCol[gridCol.length - 1]);
        }
      }

      for (let feedbackLoop of alg.feedbackLoops) {
        for (let operator of feedbackLoop) {
          if (_.includes(topmostOperators, operator)) {
            padTop = true;
          }
        }
      }
    }

    if (padTop) {
      verticalTranslation = verticalTranslation - (BOX_WIDTH);
    }

    const svgWidth = ((operatorGridWidth + 0.5) * BOX_WIDTH * 2);

    // Decreasing the height of the canvas will cut off the bottom, which is why we're translating everything up toward the top.
    const svgHeight = (BOX_WIDTH * 9) - verticalTranslation;

    let label;

    if (showLabel) {
      label = (<Typography variant='overline'>Algorithm {this.props.algorithm}</Typography>);
    }

    return (
      <Stack alignItems='center'>
        <svg viewBox={`0 0 ${svgWidth} ${svgHeight}`} width={svgWidth * scale} height={svgHeight * scale}>
          <g
            transform={`translate(0 -${verticalTranslation})`}>
            {svgContents}
          </g>
        </svg>
        {label}
      </Stack>
    );
  }
}

Algorithm.propTypes = {
  algorithm: PropTypes.number.isRequired,
  feedback: PropTypes.number.isRequired,
  scale: PropTypes.number,
  showLabel: PropTypes.bool,
};

export default Algorithm;
