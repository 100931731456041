import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import SVGParamCaption from './SVGParamCaption';


function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {

  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M", start.x, start.y,
    "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(" ");

  return d;
}

export default function Knob(props) {
  const theme = useTheme();
  const { value, label, minValue, maxValue } = props;


  const knobCircleValue = (Math.abs(value - minValue)) / Math.abs(maxValue - minValue) * 100;

  return (
    <div>
      <div>
        <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
          <path
            d={describeArc(25, 25, 20, -120, 120)}
            stroke={theme.palette.primary.light}
            strokeWidth="5"
            fill="transparent" />
          <path
            d={describeArc(25, 25, 20, -120, -120 + (240 * (knobCircleValue / 100)))}
            stroke={theme.palette.primary.dark}
            strokeWidth="6"
            fill="transparent"
          />
          <text x="25" y="25" textAnchor="middle" dominantBaseline="middle">{value}</text>
          <SVGParamCaption x="25" y="45" textAnchor="middle" dominantBaseline="middle">{label}</SVGParamCaption>
        </svg>
      </div>
    </div>
  );

}

Knob.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired
};
