import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useLoaderData } from "react-router-dom";

import { loadDX7PatchJSON } from '../components/patch-utils';
import Voice from '../components/Voice';

export default function DX7Patch() {
  const patch = useLoaderData();

  let author;
  let sourceLink;

  if (patch.AUTHOR && (patch.AUTHOR !== '_Unknown')) {
    author = <span>by {patch.AUTHOR}, </span>;
  } else {
    author = <span></span>;
  }

  if (patch.SOURCE) {
    sourceLink = <span>(<a href={patch.SOURCE}>source</a>)</span>;
  } else {
    sourceLink = <span></span>;
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      <Typography variant='h3' align='center'>{patch.NAME}</Typography>
      <Typography sx={{marginBottom: '2rem'}} variant='h6' align='center'>{author} from {patch.BANK} {sourceLink}</Typography>
      <Voice voiceId={patch.SIGNATURE} />
    </Stack>
  );
}

export async function loader({ params }) {
  return loadDX7PatchJSON(params.patchID);
}
