import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BlockIcon from '@mui/icons-material/Block';

import ActionButton from './ActionButton';
import { loadDX7Sysex } from './patch-utils';
import { Stack } from '@mui/system';
import { DriveFolderUpload, UploadFile } from '@mui/icons-material';


class MIDISetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSysexAccess: false,
      midiOutputs: null,
      selectedMidiOutput: null
    };
  }

  render() {
    let midiOutputSelector = null;
    let pushToSynthButtons = null;

    const midiOutputOptions = [];

    if (this.state.midiOutputs && this.state.midiOutputs.length > 0) {
      this.state.midiOutputs.forEach((midiOutput) => {
        midiOutputOptions.push(<MenuItem key={midiOutput.id} value={midiOutput.id}>{`${midiOutput.name} (${midiOutput.manufacturer})`}</MenuItem>);
      });

      midiOutputSelector = (<FormControl size="small">
        <InputLabel id="midi-output-label">Output</InputLabel>
        <Select
          labelId="midi-output-label"
          id="midi-output-select"
          value={this.state.selectedMidiOutput ? this.state.selectedMidiOutput.id : null}
          label="Output"
          onChange={this.selectMidiOutput.bind(this)}>
          {midiOutputOptions}
        </Select>
      </FormControl>);

      pushToSynthButtons = [
        <Tooltip title="Send Single Patch to Synth" key="patch-to-synth-button">
          <span>
            <ActionButton
              label='To Synth' visible={this.state.hasSysexAccess}
              icon={<UploadFile />} onClick={this.transferSinglePatch.bind(this)} />
          </span>
        </Tooltip>,
        <Tooltip title="Send Patch Bank to Synth" key="bank-to-synth-button">
          <span>
            <ActionButton
              label='To Synth' visible={this.state.hasSysexAccess}
              icon={<DriveFolderUpload />} onClick={this.transferPatchBank.bind(this)} />
          </span>
        </Tooltip>

      ];
    }

    return (
      <Stack spacing={1} direction='row' alignItems='center' justifyContent='center'>
        <ActionButton label="SysEx"
          icon={this.state.hasSysexAccess ? <VerifiedUserIcon /> : <BlockIcon />}
          color={this.state.hasSysexAccess ? "primary" : "secondary"}
          visible={true}
          disabled={!navigator.requestMIDIAccess}
          onClick={this.requestSysexAccess.bind(this)} />
        {midiOutputSelector}
        <Stack spacing={1} direction='column' alignItems='center' justifyContent='center'>
          {pushToSynthButtons}
        </Stack>
      </Stack>
    );
  }

  selectMidiOutput(event) {
    const outputID = event.target.value;

    for (let output of this.state.midiOutputs) {
      if (output.id === outputID) {
        this.setState({
          selectedMidiOutput: output
        });
      }
    }
  }

  requestSysexAccess() {
    return navigator.requestMIDIAccess({ sysex: true })
      .then((access) => {
        const outputs = [];

        for (let key of access.outputs.keys()) {
          outputs.push(access.outputs.get(key));
        }

        // Handler for device connection and disconnection
        access.onstatechange = (event) => {
          if (event.port.type === 'output') {
            if (event.port.state === 'connected') {
              this.setState(prevState => ({
                midiOutputs: [...prevState.midiOutputs, event.port],
                // If there was no previously selected output, select the output that was just connected.
                // Otherwise, keep the existing selection.
                selectedMidiOutput: prevState.selectedMidiOutput ? prevState.selectedMidiOutput : event.port
              }));
            } else {
              this.setState(prevState => ({
                midiOutputs: prevState.midiOutputs.filter(output => output.id !== event.port.id),
                // If you just removed the selected output, select a different one if you can.
                // Otherwise, keep the previously-selected output.
                selectedMidiOutput: (prevState.selectedMidiOutput.id === event.port.id) ? (prevState.midiOutputs.find(output => output.id !== event.port.id) || null) : prevState.selectedMidiOutput
              }));
            }

            console.log(event.port.name, event.port.manufacturer, event.port.state);
          }
        };

        this.setState({
          hasSysexAccess: access.sysexEnabled,
          midiOutputs: outputs,
          selectedMidiOutput: outputs.length > 0 ? outputs[0] : null
        });
      })
      .catch((e) => {
        this.setState({
          hasSysexAccess: false
        });
      });
  }

  async handleSysexTransfer(single_patch) {
    const sysex = await loadDX7Sysex(this.props.voiceId, single_patch);
    const data = await sysex.arrayBuffer();
    const sysexByteArray = new Uint8Array(data);

    this.state.selectedMidiOutput.send(sysexByteArray);
  }

  async transferSinglePatch() {
    await this.handleSysexTransfer(true);
  }

  async transferPatchBank() {
    await this.handleSysexTransfer(false);
  }
}

export default MIDISetup;
MIDISetup.propTypes = {
  voiceId: PropTypes.string
};
