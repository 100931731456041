import { Alert, Button, Stack, TextField, Typography } from "@mui/material";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { auth } from "../firebase";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [errorState, setErrorState] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await sendPasswordResetEmail(auth, email);
      setError("Password reset e-mail sent!");
      setErrorState('success');
    } catch (err) {
      let errorMessage;

      switch (err.code) {
        case 'auth/missing-email':
          errorMessage = 'Must provide an e-mail address';
          break;
        case 'auth/invalid-email':
          errorMessage = 'E-mail address is invalid';
          break;
        case 'auth/user-not-found':
          errorMessage = 'No user registered with that e-mail address';
          break;
        default:
          errorMessage = `An unknown error (${err.code}) occurred`;
          break;
      }

      setError(errorMessage);
      setErrorState('error');
    }
  };

  let alert;

  if (error) {
    alert = <Alert severity={errorState}>{error}</Alert>;
  } else {
    alert = null;
  }

  return (
  <Stack justifyContent="center" alignItems="center" spacing={2}>
    <Typography variant="h5">Reset your patches.fm password</Typography>
    <TextField required id="email" label="E-Mail Address" onChange={(event) => { setEmail(event.target.value) }}/>
    {alert}
    <Button onClick={handleSubmit} variant="contained">Send Password Reset E-Mail</Button>
  </Stack>
  );
}